<template>
  <BannerPrincipalSkeleton class="-mb-1" v-if="!bannersCargados" />
  <section
    class="grid grid-cols-10 -mb-1"
    :class="{
      hidden: !bannersCargados,
    }"
    v-if="!isServer && $bannersGrandes.length"
  >
    <div
      class="col-span-10 w-full justify-center md:pr-1 lg:pr-2 xl:pr-3"
      :class="[$bannersChicos.length ? 'md:col-span-7' : 'md:col-span-10']"
    >
      <Flicking
        :options="options"
        :plugins="pluginsBanner"
        class="h-full"
        ref="carruselBannerPrincipalHome"
      >
        <ImagenBanner
          v-for="(imagen, index) in $bannersGrandes"
          :images="imagen.imagesUrls"
          :measures="measuresGrande"
          :size="'xl'"
          :key="index"
          class="mx-3"
          :roundedless="true"
          :alt="imagen.alt"
          :link="imagen.link ?? ''"
          @onLoad="cargarBanner(index)"
          @click="clickBannerHeroHome(imagen.id, imagen.nombre, imagen.tipo)"
        />
        <template #viewport>
          <Paginador
            class="bottom-2.5 md:bottom-3.5 lg:bottom-7"
            :carrusel="carruselBannerPrincipalHome"
            :nombre-paginador="nombrePaginadorBannerPrincipal"
            :size="'sm'"
          />
        </template>
      </Flicking>
    </div>

    <ImagenBanner
      v-if="$bannersChicos.length"
      class="col-span-3 hidden justify-end md:flex pl-2 w-full"
      :images="$bannersChicos[0].imagesUrls"
      :measures="measuresChico"
      :size="'xl'"
      :roundedless="true"
      :alt="$bannersChicos[0].alt"
      :link="$bannersChicos[0].link ?? ''"
      @onLoad="cargarBannerChico"
      @click="
        clickBannerHeroHome(
          $bannersChicos[0].id,
          $bannersChicos[0].nombre,
          $bannersChicos[0].tipo,
        )
      "
    />
    <div
      v-if="$bannersChicos.length"
      class="bg-yellow-500 fixed bottom-0 h-4 z-10 w-full md:hidden"
      :class="{ 'hidden ': !bannerInferior }"
      @click="
        clickBannerHeroHome(
          $bannersChicos[0].id,
          $bannersChicos[0].nombre,
          $bannersChicos[0].tipo,
        )
      "
    >
      <ImagenBanner
        class="col-span-10 md:hidden fixed bottom-4 z-10"
        :closable="true"
        :images="$bannersChicos[0].imagesMobileUrls"
        :measures="measuresChico"
        :size="'l'"
        :roundedless="true"
        :alt="$bannersChicos[0].alt"
        :link="$bannersChicos[0].link ?? ''"
        @onLoad="cargarBannerChico"
        @cerrarBanner="cerrarBannerPequeño()"
      />
    </div>
  </section>
</template>
<script setup lang="ts">
  import Flicking from "@egjs/vue3-flicking";
  import { ref, computed, onMounted } from "@vue/runtime-core";
  import { useStore } from "@nanostores/vue";
  import { bannersGrandes, bannersChicos } from "@stores/app";
  import "@egjs/flicking-plugins/dist/flicking-plugins.css";
  import ImagenBanner from "@components/banners/ImagenBanner.vue";
  import BannerPrincipalSkeleton from "@components/skeletons/BannerPrincipalSkeleton.vue";
  import { clickBannerHeroHome } from "@lib/eventos";
  import Paginador from "@ui/Paginador.vue";
  import { plugins } from "@lib/bullets";

  const $bannersChicos = useStore(bannersChicos);
  const $bannersGrandes = useStore(bannersGrandes);
  const bannerGrandeCargado = ref<boolean>(false);
  const bannerChicoCargado = ref<boolean>(false);
  const bannerInferior = ref<boolean>(true);

  const options = {
    align: "prev",
    circular: true,
    panelsPerView: 1,
    autoResize: true,
    noPanelStyleOverride: false,
    preventDefaultOnDrag: true,
  };

  const measuresGrande = {
    "2xl": {
      width: 2850,
      height: 960,
    },
    xl: {
      width: 1900,
      height: 640,
    },
    l: {
      width: 950,
      height: 320,
    },
  };

  const measuresChico = {
    "2xl": {
      width: 1200,
      height: 960,
    },
    xl: {
      width: 800,
      height: 640,
    },
    l: {
      width: 1920,
      height: 150,
    },
  };

  const isServer = ref<boolean>(true);
  const carruselBannerPrincipalHome = ref<Flicking>();
  const nombrePaginadorBannerPrincipal = "paginador-banner-principal";
  const pluginsBanner = plugins(
    nombrePaginadorBannerPrincipal,
    true,
    false,
    "sm",
  );

  onMounted(() => {
    isServer.value = false;
  });

  const cargarBanner = (index: number) => {
    if (index === 0) bannerGrandeCargado.value = true;
  };

  const cargarBannerChico = () => {
    bannerChicoCargado.value = true;
  };

  const bannersCargados = computed<boolean>(() => {
    if ($bannersChicos.value.length) {
      return bannerGrandeCargado.value && bannerChicoCargado.value;
    } else {
      return bannerGrandeCargado.value;
    }
  });

  const cerrarBannerPequeño = () => {
    bannerInferior.value = false;
  };
</script>
