<template>
  <div>
    <section
      class="w-full aspect-[95/32] md:aspect-[768/181] animate-pulse bg-gray-50"
    />
    <div
      class="w-full h-12 animate-pulse bg-gray-50 sm:h-14 fixed bottom-0 z-20 md:hidden"
    />
  </div>
</template>
